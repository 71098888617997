import React from "react"
import Seo from "../../components/Seo/Seo"
import * as styles from  "./community.module.sass"
import PageTransition from "../../components/PageTransition";
import BlockQuote from "../../components/BlockQuote";

const CommunityPage = (props) => (

	<PageTransition {...props}>

		<Seo title="Interesting" keywords={[]} />

		<div className={styles.communityPage}>

			<header>

				<h1 id="main-title"><span>Deviant Robot Community</span>Discord rules and guidelines</h1>

			</header>



			<article className={styles.Page}>

				<BlockQuote cite="Oscar Wilde">
					It is absurd to divide people into good and bad. People are either charming or tedious.
				</BlockQuote>

					<h2>The Rules</h2>

					<p>We only have two rules, if you transgress these rules you will be ask to change how you are interacting with our community and may be band altogether.</p>

					<ol>
						<li>Don't be tedious.</li>
						<li>Don't make us feel we need to make new rules</li>
					</ol>

					<h2>The Guidelines</h2>

					<p>The guidelines are here to give some suggestions at the sorts of things we don't want to to see. Moderators and Administrators reserve the right to use their own discretion regardless of any rule or guidelines and use any tools at there disposal to ensure that the server is kept harmonious.</p>

					<h3>General</h3>

					<ol>
						<li>Make an honest attempt to have a civil conversation in a respectful manner.</li>
						<li>Treat everyone and their views charitably, even if you don't like or agree with them.</li>
						<li>Don't be disruptive.</li>
						<li>No promotions or advertisements.</li>
						<li>No inappropriate nicknames or profile pictures.</li>
					</ol>

					<h3>NSFW Content</h3>

					<ol start="6">
						<li>No sexually explicit, pornographic or other NSFW content outside of the NSFW channels.</li>
						<li>NSFW content must only be posted for the purpose of engaging in meaningful discussion.</li>
						<li>If you aren’t sure if something should be treated as NSFW then it should be treated as NSFW.</li>
						<li>Moderators and Administrators will use their own discretion in defining offensive, sexually explicit, offensive or NSFW content as well as the intent under which it is posted.</li>
					</ol>

					<h3>It should go without saying but...</h3>

					<ol start="13">

						<li>No illegal content or activity.</li>
						<li>Follow Discord Community Guidelines (https://discord.com/guidelines) and Discord Terms of Service (https://discord.com/terms).</li>

					</ol>

			</article>

		</div>

	</PageTransition>

)

export default CommunityPage
